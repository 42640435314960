import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData } from '@/mock/SiteData';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { TitleCopy } from '@/components/shared/title-copy';
import { ImageGallery } from '@/components/shared/image-gallery';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import JavascriptLogo from '@/assets/images/technologies/javascript.webp';
import SassLogo from '@/assets/images/technologies/sass.webp';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy = CaseStudyData.find((m) => m.id === 'dr-b');

const DrBubba = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const titleCopyThird = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const animatedTagCopyRef = useScrollAnimation();

  const lightSeparatorRef = useScrollAnimation();

  const darkSeparatorRef = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="flex flex-col gap-36 bg-white py-36">
          <TitleCopy
            ref={titleCopyFirst}
            title="The Client"
            description="Dr. B enables online patients to get and fill the everyday prescriptions they depend on from the commodity of their home, while expanding access to care for those who need it most."
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGalleryFirst}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[
              { src: '/images/case-studies/drbubba/mock-1.webp', alt: 'image1' },
              { src: '/images/case-studies/drbubba/mock-2.webp', alt: 'image2' },
            ]}
          />
          <TitleCopy
            ref={titleCopySecond}
            title="The Need"
            description={`All this medical data that goes through Dr. B is analyzed by health professionals in an ERP platform called canvas medical, unluckily, those professionals seemed to be struggling with the platformand in need of extensive training for them to use it effectively.\n\nSo we decided we needed to make it easier to use, without compromising on functionality or waiting for them to get a new update out.`}
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGallerySecond}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[{ src: '/images/case-studies/drbubba/mock-3.webp', alt: 'image3' }]}
          />
          <TitleCopy
            ref={titleCopyThird}
            title="The Solution"
            description={`As a result of that need, we started quite an interesting and challenging project, where using a chrome extension we injected JS and CSS into the page effectively altering both the UI and the functionality.\n\nThe changes ranged between the restyling of an almost unreadable table into a clean and useful one, to hiding away a confusing navigation and displaying all the actions for one patient file on a single card. We even expanded on those actions to make the professionals need less clicks and input less data, for example, most of the questionnaires now autocomplete with the available data, without the need for the user to type in everything.\n\nIt was a very challenging project mainly due to the fact that we had to interact with a react application that was running completely separate from the extension, with render cycles, asynchronous state changes and events that we needed to wait for in a completely separate runtime. But, thanks to our amazing team, we managed to figure it out and right now the platform needs next to no training to be used effectively.\n We could say “problem solved” ;)`}
            className={ANIMATED_CLASSNAME}
          />
          <Separator
            ref={lightSeparatorRef}
            className={cn('-mb-36 mx-auto max-w-7xl', ANIMATED_CLASSNAME)}
          />
        </div>
        <div className="animated-copy animate-transition flex flex-col gap-36 bg-loop-black py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              <img className="w-40" src={JavascriptLogo} key="javascript" alt="javascript" />,
              <img className="w-40" src={SassLogo} key="sass" alt="sass" />,
            ]}
            description=""
            className={{
              section: cn(ANIMATED_CLASSNAME, 'lg:items-center'),
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              ref={animatedTagCopyRef}
              tag="the result"
              className={{
                section: cn(ANIMATED_CLASSNAME, 'mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: 'We delivered a website that, in addition to its <w>refinement<w> and <w>simplicity<w>, reinforces the values and concepts of the brand.',
                trigger: '.animated-copy',
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: 'mx-auto mt-12 px-4 md:px-8 xl:px-0' }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: 'Easy to access, <w>dynamic<w> and <w>agile<w>, a website that met the needs of the client and that its users will enjoy browsing. Go ahead! We invite you to discover a site with a <w>narrative<w> like no other.',
                trigger: '.animated-copy',
                revealDelay: 0.75,
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
          </div>
          <Separator
            ref={darkSeparatorRef}
            className={cn('mx-auto max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default DrBubba;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO title="Loop3 Studio - Dr. Bubba" description={`${caseStudy.hero.heroDescriptionText}`} />
    );
  }
};
